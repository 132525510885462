import logo from './profile.jpg';
import './App.css';
import 'animate.css';

function App() {
  return (
    <div className='bg-gradient-to-b from-gray-500 via-white to-white flex items-center justify-center h-screen'>
      <div>
        <div className='md:flex items-center animate__animated animate__flash animate__slow text-center'>
          <div className='drop-shadow-lg mb-5 mx-auto lg:w-[25em] md:w-[23em] w-[15em] rounded-full border-[0.4em] border-black overflow-hidden'>
            <img src={logo} />
          </div>
          <div className='md:ml-5'>
            <h1 className='lg:text-8xl md:text-6xl text-2xl font-bold'>
              |NoMu|
            </h1>
            <p className='lg:text-4xl md:text-2x1 text-lg mt-[1.25em]'>
              Can do everything, If I can 😝
            </p>
          </div>
        </div>
        
        <div className='flex items-center justify-center mt-[60px] animate__animated animate__backInUp animate__slow'>
          <a className='drop-shadow-lg bg-white lg:text-6xl md:text-4xl text-xl px-4 py-3 border-[5px] border-black overflow-hidden rounded-[20px] mx-[20px] md:mx-[50px]' 
          href="https://www.facebook.com">
            <i class="fa-brands fa-facebook"></i> 
          </a>
          <a className='drop-shadow-lg bg-white lg:text-6xl md:text-4xl text-xl px-4 py-3 border-[5px] border-black overflow-hidden rounded-[20px] mx-[20px] md:mx-[50px]' 
          href="https://twitter.com/Beam3876">
            <i class="fa-brands fa-twitter"></i>
          </a>
          <a className='drop-shadow-lg bg-white lg:text-6xl md:text-4xl text-xl px-4 py-3 border-[5px] border-black overflow-hidden rounded-[20px] mx-[20px] md:mx-[50px]' 
          href="https://github.com/IM-Nuthapat">
            <i class="fa-brands fa-github"></i> 
          </a>
        </div>

        <div className='flex items-center justify-center mt-[60px] animate__animated animate__fadeInUpBig animate__slower'>
            <p className='lg:text-xl md:text-2x1 text-lg mt-[1.25em] font-bold'>
              Made with By 🤍|NoMu|🖤
            </p>
        </div>
        
      </div>
    </div>
    
  );
}

export default App;
